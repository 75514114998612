<template lang="pug">
div.container
  el-card(style="overflow: unset;")
    el-form(:inline="true")
      el-form-item
        el-input(
          ref="uidInputRef"
          v-model="params.studyInstanceUid"
          placeholder="StudyInstanceUID"
          clearable
          @change="getStudies"
        )
      el-form-item
        el-select(
          v-model="params.type"
          placeholder="Тип"
          clearable
          @change="getStudies"
        )
          el-option(v-for="t in tasksStore.types" :label="t.name" :value="t.key")
      el-form-item
        el-date-picker(
          v-model="dateRange"
          type="daterange"
          format="DD.MM.YYYY"
          range-separator="—"
          @change="applyDateRange"
          clearable
        )
      el-form-item
        el-button(
          tag="a"
          plain
          round
          :href="getCsvLink()"
        ) Скачать .csv
  el-card.studies-table-container(body-style="height: 60%; flex-grow: 1;")
    el-scrollbar
      el-table(:data="studiesStore.studies" table-layout="auto" v-loading="loading")
        el-table-column(label="Study Instance UID", prop="studyInstanceUid")
        el-table-column(label="Kernel", prop="kernel")
        el-table-column(label="Spacing", prop="spacing")
        el-table-column(label="Comment", prop="comment")
        el-table-column(label="UID элемента задачи", prop="entryUid")
    template(v-slot:footer)
      el-pagination.center(
        layout="total, prev, pager, next, sizes"
        style="margin: 0;"
        :total="studiesStore.total"
        v-model:current-page="params.page"
        v-model:page-size="params.perPage"
        :page-sizes="[10, 30, 50, 100]"
        @change="getStudies"
      )
</template>

<script setup lang="ts">
import { useStudiesStore } from '@/store/studies'
import { useTasksV2Store } from '@/store/tasks_v2'

const studiesStore = useStudiesStore()
const tasksStore = useTasksV2Store()
const params = ref({
  page: 1,
  perPage: 10,
  studyInstanceUid: undefined as string | undefined,
  type: undefined as string | undefined,
  since: undefined as Date | undefined,
  to: undefined as Date | undefined,
})
const uidInputRef = ref<any>()
const dateRange = ref<any[]>([undefined, undefined])
const loading = ref(false)

const applyDateRange = () => {
  if (dateRange.value === null) {
    dateRange.value = [undefined, undefined]
  }

  params.value.since = dateRange.value[0]
  params.value.to = dateRange.value[1]

  getStudies()
}

const getCsvLink = () => studiesStore.csvLink(params.value)

const getStudies = () => {
  loading.value = true
  studiesStore.get(params.value)
    .finally(() => loading.value = false)
}

const handleKeydown = (e: KeyboardEvent) => {
  if (e.key !== '/' || document.activeElement === uidInputRef.value?.input) {
    return
  }

  e.preventDefault()
  uidInputRef.value?.focus()
}

onMounted(() => {
  tasksStore.getTaskTypes()
  getStudies()
  document.addEventListener('keydown', handleKeydown)
})
onUnmounted(() => document.removeEventListener('keydown', handleKeydown))
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.el-form {
  display: flex;
}

.el-form-item {
  margin-bottom: 0;
  flex-grow: 1;
}

.studies-table-container {
  display: flex;
  flex-direction: column;
  margin: 0;
}
</style>
