<template lang="pug">
login(v-if="authStore.loggedIn === false")
el-container(v-else).vh-100
  el-aside.vh-100(@vue:mounted="observeResize")
    navigation
  el-main(v-loading.fullscreen.lock="loading")
    router-view(v-if="authStore.loggedIn")
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type { VNode } from 'vue';
import { useAuthStore } from '@/store/auth';
import Login from '@/components/Login.vue';
import Navigation from '@/components/Navigation.vue';

const authStore = useAuthStore();

const loading = ref(true);

authStore.getCurrentUser().finally(() => loading.value = false);

const ro = new ResizeObserver((_: ResizeObserverEntry[]) => {});

const observeResize = (n: VNode) => {
  ro.observe(n.el as Element)
}
</script>

<style>
* {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-size: 14px;
}

body {
  overflow: hidden;
}

.el-aside {
  width: unset !important;
  padding-right: 1.5%;
}

.el-main {
  padding: var(--el-main-padding) var(--el-main-padding) var(--el-main-padding) 0 !important;
}

.el-pagination {
  margin-top: 1rem;
}

.el-card {
  margin-bottom: 1rem;
}

.center {
  text-align: center;
  justify-content: center;
}

a {
  text-decoration: None;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.vh-100 {
  height: 100vh;
}

.w220 {
  width: 220px !important;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-inline: 1rem;
}

.logo__bottom {
  max-height: 50px;
}

.el-card__header {
  font-size: 1.2rem;
  font-weight: 600;
}
</style>
