<template lang="pug">
el-dialog(v-model="createTaskDialogVisible" :align-center="true" title="Создание задачи")
  CreateTaskForm(@created="handleCreated()")
el-dialog(v-model="showTaskDialogVisible" width="75%" :align-center="true" title="Задача")
  TaskDetails(:taskUID="selectedTaskUID")

div.container
  el-card(style="overflow: unset;")
    el-form(:inline="true")
      el-form-item
        el-button(
          v-if="authStore.isAdmin"
          :icon="Edit"
          @click="handleCreateButtonClick"
        ) Создать

      el-form-item(style="width: 10%")
        el-select(placeholder="Тип" v-model="params.type" @change="getTasks" clearable)
          el-option(v-for="_type in tasksStore.types" :label="_type.name" :value="_type.key" align="center")

      el-form-item(style="width: 10%")
        el-select(placeholder="Статус" v-model="params.status" @change="getTasks" clearable)
          el-option(v-for="(label, value) in statuses.taskStatusText" :label="label" :value="value")

      el-form-item
        el-date-picker(
          v-model="dateRange"
          type="daterange"
          format="DD.MM.YYYY"
          range-separator="—"
          value-format="x"
          @change="applyDateRange"
          clearable
        )

      el-form-item(style="width: 20%")
        el-input(
          ref="uidInputRef"
          v-model="params.uid"
          placeholder="UID задачи или файла"
          clearable
          @change="applyUidFilter"
        )
  el-card.tasks-table-container(body-style="height: 60%; flex-grow: 1;")
    el-scrollbar
      el-table(:data="tasksStore.tasks" v-loading="loading" table-layout="auto")
        el-table-column(align="center" v-slot="scope: { row: Task }")
          el-button(
            v-if="scope.row.performer || authStore.isAdmin"
            round
            :disabled="scope.row.status <= statuses.TASK_STATUS_PENDING && !authStore.isAdmin"
            @click="handleOpenButtonClick(scope.row)"
          ) Открыть
          el-button(
            v-if="tasksStore.isActionAvailable(scope.row, Actions.PERFORM)"
            round
            :disabled="scope.row.status <= statuses.TASK_STATUS_PENDING"
            @click="handlePerformButtonClick(scope.row)"
          ) Выполнить
        el-table-column(prop="type.name" label="Тип" align="center")
        el-table-column(prop="status" label="Статус" align="center" v-slot="scope: { row: Task }")
            el-text(:type="statuses.textTypeByTaskStatus[scope.row.status]")
              | {{ statuses.taskStatusText[scope.row.status] }}
        el-table-column(prop="performer" label="Исполнитель" align="center" v-slot="scope: { row: Task }")
          | {{ scope.row.performer || '—' }}
        el-table-column(prop="createdBy" label="Создатель" align="center")
    template(v-slot:footer)
      el-pagination.center(
        layout="total, prev, pager, next, sizes"
        style="margin: 0;"
        :total="tasksStore.total"
        v-model:current-page="params.page"
        v-model:page-size="params.perPage"
        :page-sizes="[10, 30, 50, 100]"
        @change="getTasks"
      )
</template>

<style lang="scss" scoped>
.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.tasks-table-container {
    display: flex;
    flex-direction: column;
    margin: 0;
}

.el-form-item {
  margin-bottom: 0;
}
</style>

<script setup lang="ts">
import { Edit } from '@element-plus/icons-vue'
import { useTasksV2Store } from '@/store/tasks_v2'
import { useAuthStore } from '@/store/auth'
import * as statuses from '@/utils/statuses'
import { Actions } from '@/utils/actions'
import type { Task, TasksListRequest } from '@/models/tasks_v2'

const loading = ref(false)
const dateRange = ref<any[]>([undefined, undefined])
const params = ref<TasksListRequest>({
    page: 1,
    perPage: 100,
    type: undefined,
    orderBy: undefined,
    status: undefined,
    since: undefined,
    to: undefined,
    uid: undefined,
})
const uidInputRef = ref<any>()
const createTaskDialogVisible = ref(false)
const showTaskDialogVisible = ref(false)
const selectedTaskUID = ref<string>('')
const authStore = useAuthStore()
const tasksStore = useTasksV2Store()


const handleCreated = () => {
    createTaskDialogVisible.value = !createTaskDialogVisible.value
    getTasks()
}

const handleCreateButtonClick = () => {
    tasksStore.getUsers()
    createTaskDialogVisible.value = !createTaskDialogVisible.value
}

const handleOpenButtonClick = (task: Task) => {
    selectedTaskUID.value = task.uid
    showTaskDialogVisible.value = true
}

const handlePerformButtonClick = (task: Task) => {
    tasksStore.setTaskPerformer(task.uid)
}

const applyDateRange = () => {
    params.value.since = dateRange.value[0]

    dateRange.value[1]
    && (params.value.to = dateRange.value[1] + 1000 * 60 * 60 * 24 - 1)
    || (params.value.to = undefined)

    getTasks()
}

const applyUidFilter = () => {
  uidInputRef.value?.blur()
  getTasks()
}

const handleKeydown = (e: KeyboardEvent) => {
  if (e.key !== '/' || document.activeElement === uidInputRef.value?.input) {
    return
  }

  e.preventDefault()
  uidInputRef.value?.focus()
}

const getTasks = () => {
    loading.value = true
    tasksStore.getTasks(params.value)
      .finally(() => loading.value = false)
}

const getTypes = () => tasksStore.getTaskTypes()

onMounted(() => {
  getTypes()
  getTasks()
  document.addEventListener('keydown', handleKeydown)
})
onUnmounted(() => document.removeEventListener('keydown', handleKeydown))
</script>
