import { createRouter, createWebHistory } from 'vue-router';
import Profile from '@/components/Profile/Profile.vue'
import TasksV2 from '@/components/TasksV2/TasksV2.vue'
import AnnotationResults from '@/components/TasksV2/AnnotationResults.vue'
import Studies from '@/components/Studies/Studies.vue'
import ReportsV2 from '@/components/ReportsV2/ReportsV2.vue'
import Stat from '@/components/Stat/Stat.vue'
import Notifications from '@/components/Notifications/Notifications.vue';

const routes = [
    { path: '/profile', component: Profile },
    { path: '/', component: TasksV2 },
    { path: '/tasks', component: TasksV2 },
    { path: '/annotation-results', component: AnnotationResults },
    { path: '/studies', component: Studies },
    { path: '/reports', component: ReportsV2 },
    { path: '/stat', component: Stat },
    { path: '/notifications', component: Notifications },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
