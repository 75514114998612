<template lang="pug">
el-menu.vh-100.menu(
  :default-active="router.currentRoute.value.path"
  :collapse="isCollapsed"
  :collapse-transition="false"
)
  el-button#collapse-btn(
    @click="isCollapsed = !isCollapsed"
    :icon="isCollapsed ? DArrowRight : DArrowLeft"
    plain
  )
  router-link(v-for="item in menu.filter(i => checkIfAllowed(i))" :to="item.address")
    el-menu-item(:index="item.address")
      component.h2em(:is="item.icon")
      el-button(
        v-if="!isCollapsed"
        link
        :class="{ underline: router.currentRoute.value.path === item.address }"
        @click="() => item.callback && item.callback()"
      ) {{ item.name }}
  div.filler(style="margin-top: auto;")
  router-link(v-for="item in menuBottom.filter(i => checkIfAllowed(i))" :to="item.address")
    el-menu-item(:index="item.address")
      component.h2em(:is="item.icon")
      el-button(
        v-if="!isCollapsed"
        link
        :class="{ underline: router.currentRoute.value.path === item.address }"
        @click="() => item.callback && item.callback()"
      ) {{ item.name }}
</template>

<script setup lang="ts">
import {
  List,
  Document,
  Link,
  Files,
  User,
  DArrowLeft,
  DArrowRight,
  Histogram,
  Notification,
} from '@element-plus/icons-vue';
import Logout from '@/assets/logout-icon.svg';
import { useAuthStore } from '@/store/auth';

const router = useRouter();
const isCollapsed = ref(false);
const loading = ref(true);
const authStore = useAuthStore();

type MenuItem = {
  name: string
  address: string
  icon: Component
  requiresAdmin: boolean
  callback?: () => void
}

const menu: MenuItem[] = [
  {
    name: 'Задачи',
    address: '/',
    icon: List,
    requiresAdmin: false,
  },
  {
    name: 'Выгрузка результатов',
    address: '/annotation-results',
    icon: Link,
    requiresAdmin: true,
  },
  {
    name: 'Учет исследований',
    address: '/studies',
    icon: Files,
    requiresAdmin: true,
  },
  {
    name: 'Отчеты',
    address: '/reports',
    icon: Document,
    requiresAdmin: true,
  },
  {
    name: 'Статистика',
    address: '/stat',
    icon: Histogram,
    requiresAdmin: false,
  },
  {
    name: 'Уведомления',
    address: '/notifications',
    icon: Notification,
    requiresAdmin: false,
  },
];

const menuBottom: MenuItem[] = [
  {
    name: 'Профиль',
    address: '/profile',
    icon: User,
    requiresAdmin: false,
  },
  {
    name: 'Выйти',
    address: '',
    icon: h(
      'img',
      { src: Logout, style: { height: '2em' } },
    ),
    requiresAdmin: false,
    callback: () => authStore.logout(),
  },
];

const checkIfAllowed = (menuItem: MenuItem) => menuItem.requiresAdmin ? authStore.isAdmin : true

authStore.getCurrentUser().finally(() => loading.value = false);
</script>

<style lang="scss" scoped>
.underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.h2em {
  height: 2em;
}

.menu {
  display: flex;
  flex-direction: column;
}

.menu .el-button {
  margin-left: 1em;
}

#collapse-btn {
  position: absolute;
  right: 0;
  top: 50%;
  width: 1%;
  transform: translate(50%, -50%);
}
</style>
