import { defineStore } from 'pinia'
import axios from '@/utils/axios'
import type { Study, GetStudiesRequest } from '@/models/studies'
import type { ListResponse } from '@/models/common'

type State = {
  studies: Study[]
  total: number
}

export const useStudiesStore = defineStore('studies', {
  state: (): State => ({
    studies: [],
    total: 0,
  }),
  actions: {
    async get(params: GetStudiesRequest) {
      const { data } = await axios.get<ListResponse<Study>>(
        'v2/studies-list',
        {
          params: {
            ...params,
            since: params.since ? params.since.getTime() / 1000 : undefined,
            to: params.to ? params.to.getTime() / 1000 : undefined,
          },
        },
      )

      this.studies = data.results
      this.total = data.total as number
    },

    csvLink(params: GetStudiesRequest) {
      return axios.getUri({
        url: '/v2/studies-list',
        params: {
          ...params,
          since: params.since ? params.since.getTime() / 1000 : undefined,
          to: params.to ? params.to.getTime() / 1000 : undefined,
          csv: true,
        },
      })
    },
  },
})
